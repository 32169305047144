import { createRouter, createWebHashHistory } from 'vue-router';
import dashboardRouter from '@/modules/dashboard/router'
import authRouter from '@/modules/auth/router'
import homeRouter from '@/modules/home/router'

const routes = [
  {
    path: '/',
    ...homeRouter
  },
  {
    path: '/dashboard',
    ...dashboardRouter
  },
  {
    path: '/auth',
    ...authRouter
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
