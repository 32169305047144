import grtApi from "@/api/grtApi"

export const loginUser = async({ commit }, form) => {
    try {
        await grtApi.get('/sanctum/csrf-cookie')
    } catch (error) {
        return { ok: false, message: 'Hubo un problema. Favor de intentarlo nuevamente.' }
    }

    try {
        const { data } = await grtApi.post('/login', form)
        const user = { name: data.nombre, email: data.email, id: data.id }

        commit('loginUser', user)

        return { ok: true, message: 'Has iniciado sesión con tu cuenta' }
    } catch (error) {
        return { ok: false, message: error.response.data.errors }
    }
}

export const loginCliente = async({ commit }, form) => {
    try {
        await grtApi.get('/sanctum/csrf-cookie')
    } catch (error) {
        return { ok: false, message: 'Hubo un problema. Favor de intentarlo nuevamente.' }
    }

    try {
        const { data } = await grtApi.post('/login-cliente', form)
        const cliente = { name: data.nombre, email: data.email, id: data.id }

        commit('loginCliente', cliente)

        return { ok: true, message: 'Has iniciado sesión con tu cuenta' }
    } catch (error) {
        return { ok: false, message: error.response.data.errors }
    }
}

export const checkUser = async({ commit }) => {
    try {
        const { data } = await grtApi.get('/api/user')
        const { nombre, email, id } = data
        const user = {
            nombre,
            email,
            id
        }

        commit('loginUser', user)
        return { ok: true }
    } catch (error) {
        commit('logout')
        return { ok: false }
    }
}

export const checkCliente = async({ commit }) => {
    try {
        const { data } = await grtApi.get('/api/cliente')

        const { nombre, email, id } = data
        const cliente = {
            nombre,
            email,
            id
        }

        commit('loginCliente', cliente)
        return { ok: true }
    } catch (error) {
        commit('logoutCliente')
        return { ok: false }
    }
}

export const logout = async({ commit }) => {
    try {
        await grtApi.get('/sanctum/csrf-cookie')
    } catch (error) {
        return { ok: false, message: 'Hubo un problema. Favor de intentarlo nuevamente.' }
    }

    try {
        await grtApi.post('/logout')
        commit('logout')

        return { ok: true, message: 'Has iniciado sesión con tu cuenta' }
    } catch (error) {
        console.log(error)
        return { ok: false, message: error.response.data.errors }
    }
}
