import infoAuth from '@/modules/auth/router/cliente-guard';

export default {
    name: 'inicio',
    component: () => import('@/modules/home/layouts/HomeLayout'),
    children: [
        {
            path: '',
            name: 'inicio.home',
            component: () => import('@/modules/home/views/HomeView')
        },
        {
            path: '/evento/:id',
            name: 'inicio.evento',
            component: () => import('@/modules/home/views/EventoHome'),
            beforeEnter: [ infoAuth ]
        }
    ]
}