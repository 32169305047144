import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import VCalendar from 'v-calendar';

import './assets/css/tailwind.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'v-calendar/style.css';
import 'animate.css';

library.add(fas, far);

createApp(App).use(store).use(router).use(VCalendar, {}).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
