export const loginUser = (state, user) => {
    state.user = user
    state.status = 'autenticado'
}

export const loginCliente = (state, cliente) => {
    state.cliente = cliente
    state.statusCliente = 'autenticado'
}

export const logout = (state) => {
    state.user = null
    state.status = 'no-autenticado'
}

export const logoutCliente = (state) => {
    state.cliente = null
    state.statusCliente = 'no-autenticado'
}