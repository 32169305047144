import { createStore } from 'vuex'
import auth from '@/modules/auth/store'

export default createStore({
  state: {
    isLoading: false
  },
  getters: {
  },
  mutations: {
    setLoading(state, cargando) {
      state.isLoading = cargando
    }
  },
  actions: {
  },
  modules: {
    auth
  },
});
