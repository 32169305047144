import estaAutenticado from '@/modules/auth/router/auth-guard';

export default {
    name: 'dashboard',
    component: () => import('@/modules/dashboard/layouts/DashLayout'),
    children: [
        {
            path: '',
            name: 'dashboard.inicio',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/dashboard/views/PanelView')
        },
        {
            path: 'evento/:id',
            name: 'eventos.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/eventos/views/EventosView')
        }
    ]
}